import numbro from 'numbro';
import i18n, { translateBlockValue } from 'i18n';
import {
    formatDate,
    formatMonth,
    formatQuarter,
    formatYear,
    formatSchoolYear,
} from 'helpers/formatHelper';
import { getProjectionVentilationReadableValue } from 'routes/Admin/Budget/BudgetManagement/BudgetList/CellRenderers/ProjectionVentilationCellRenderer/helpers/dataHelpers';
import { ValueFormatterFunc, ValueFormatterParams } from 'ag-grid-community';
import { UNKNOWN_DATUM } from 'business/constants';
import { isFloatParsable, isNotANumber } from './helpers';

export type ValueFormatterT = (
    params: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value?: any;
        context?: FlowAnyObject;
    },
    forceSign?: boolean,
) => string;

export type KeyCreatorT = (params: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    context?: FlowAnyObject;
}) => string;

const getNumbroParameters = (
    params: Partial<ValueFormatterParams>,
    trimMantissa: boolean = true,
    optionalMantissa: boolean = false,
) => {
    const numbroParameter: numbro.Format = {
        trimMantissa,
        optionalMantissa,
        spaceSeparated: true,
        thousandSeparated: true,
        mantissa: 2,
    };
    if (
        params.context?.decimalPrecision !== undefined &&
        params.context?.decimalPrecision !== null
    ) {
        numbroParameter.mantissa = params.context.decimalPrecision;
    }

    if (['million', 'thousand'].includes(params.context?.displayBy)) {
        numbroParameter.forceAverage = params.context?.displayBy;
    }
    return numbroParameter;
};

/**
 * Aims to format the input as a currency by displaying 2 digits if the number is a decimal
 * and no digits if the number is an integer. Depending on forceSign it can also prefix the
 * value with a sign.
 * @param params - ValueFormatterParams
 * @param forceSign - boolean - If true, prefixes the number with his sign.
 * @param optionalMantissa - boolean - If true, removes the decimal part when it equals 0.
 * @returns string - Formatted currency.
 */
export const currencyFormatter = (
    params: Partial<ValueFormatterParams>,
    forceSign: boolean = false,
    optionalMantissa: boolean = true,
) => {
    if (!isFloatParsable(params.value)) return '';

    const floatValue = parseFloat(params.value);

    const numbroParameter = forceSign
        ? {
              ...getNumbroParameters(params, false, optionalMantissa),
              forceSign: true,
          }
        : getNumbroParameters(params, false, optionalMantissa);
    return isNotANumber(floatValue)
        ? ''
        : numbro(params.value).formatCurrency(numbroParameter);
};

export const floatFormatter = (params: ValueFormatterParams) => {
    const numbroParameter = getNumbroParameters(params);
    const floatValue = parseFloat(params.value);

    return isNotANumber(floatValue)
        ? ''
        : numbro(floatValue).format(numbroParameter);
};

export const integerFormatter = (params: ValueFormatterParams) => {
    const numbroParameter = {
        trimMantissa: true,
        spaceSeparated: true,
        thousandSeparated: true,
        mantissa: 0,
    };
    const integerValue = parseInt(params.value, 10);

    return isNotANumber(integerValue)
        ? ''
        : numbro(integerValue).format(numbroParameter);
};

export const percentageFormatter: ValueFormatterT = (params) =>
    // This ternary is pretty defensive, it should never happen as we should always send number to this formmater
    isNotANumber(parseInt(params.value, 10))
        ? '-'
        : numbro(params.value).format({
              trimMantissa: true,
              spaceSeparated: true,
              thousandSeparated: true,
              mantissa: 2,
              output: 'percent',
          });

export const projectionVentilationFormatter = (
    params: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: any;
        context?: FlowAnyObject;
    },
    softwares: Set<string>,
) =>
    params.value
        ? getProjectionVentilationReadableValue(params.value, softwares)
        : '';

export const translateStatus = (status: string) =>
    i18n.t(`sentence:budgetProposal.${status}`);

export const statusFormatter: ValueFormatterT = (params) =>
    params.value ? translateStatus(params.value) : '';

const dimensionWithObjectValueFormatter: ValueFormatterFunc = (
    params: ValueFormatterParams,
): string => params.value?.label ?? params.value;

export const categoryFormatter: KeyCreatorT = (
    params: ValueFormatterParams,
) => {
    const value = dimensionWithObjectValueFormatter(params);
    if (!value || value === '-' || value === UNKNOWN_DATUM) {
        return '-';
    }

    return translateBlockValue(value, true);
};

export const dateFormatter = (params: ValueFormatterParams) => {
    const { value } = params;
    if (!value || value === '-' || value === UNKNOWN_DATUM) {
        return '-';
    }
    return formatDate(value);
};

export const timeFormatter = (params: ValueFormatterParams) => {
    const { value } = params;
    if (!value || value === '-' || value === UNKNOWN_DATUM) {
        return '-';
    }
    const timeString = new Date(value).toUTCString();
    const regex = /(\d{2}):(\d{2}):\d{2}/;
    const match = timeString.match(regex);
    if (!match) {
        return '-';
    }

    return `${match[1]}:${match[2]}`;
};

export const monthFormatter: ValueFormatterT = (params) => {
    const { value } = params;
    if (!value || value === '-' || value === UNKNOWN_DATUM) {
        return '-';
    }
    return formatMonth(value);
};

export const quarterFormatter: ValueFormatterT = (params) => {
    const { value } = params;
    if (!value || value === '-' || value === UNKNOWN_DATUM) {
        return '-';
    }
    return formatQuarter(value);
};

export const yearFormatter: ValueFormatterT = (params) => {
    const { value } = params;
    if (!value || value === '-' || value === UNKNOWN_DATUM) {
        return '-';
    }
    return formatYear(value);
};

export const schoolYearFormatter: ValueFormatterT = (params) => {
    const { value } = params;
    return formatSchoolYear(value);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getValueFormatter = (params: any): null | ValueFormatterT => {
    const colDef = params.column.getColDef();
    if (colDef.valueFormatter) {
        return colDef.valueFormatter;
    }
    // Value formatter is not kept in the col definition of the row group,
    // We have to find it through its original coldef
    if (colDef.showRowGroup) {
        return (
            params.api.getColumn(colDef.showRowGroup)?.getColDef()
                ?.valueFormatter ?? null
        );
    }
    return null;
};

export const objectWithLabelFormatter: ValueFormatterFunc = ({
    value,
}: ValueFormatterParams): string => {
    if (value && typeof value === 'object' && 'label' in value) {
        return value.label || '';
    }
    try {
        return JSON.parse(value).label;
    } catch {
        return value;
    }
};

/*
Excel documents value are formatted differently than tcd.
Here is the list of the formatter used in an excel document. We voluntarily omit percent and currency formatter.
*/
export const excelFormatters = [
    yearFormatter,
    quarterFormatter,
    monthFormatter,
    dateFormatter,
    objectWithLabelFormatter,
];
